<template>
               
  <div class="docs-main has-text-left">
      <div class="docs-main-container">
         

        <!-- models -->
        <h1 class="title">Expo</h1>
        <h2 class="subtitle">Here are users of the API. I want to thank them for getting in touch.</h2>
        <div class="content">
         
          <div class="tile is-ancestor">
              <!-- HELLADIC INFO -->
              <div class="tile is-4">
                  <div class="tile is-parent">
                      <div class="card">
                        <div class="card-image">
                          <figure class="image is-6by3">
                            <a href="http://www.helladic.info/" target="_blank" rel="noopener noreferrer">
                            <img src="../assets/expo/Helladic.jpg" alt="The Mycenaean Atlas Project">
                            </a>
                          </figure>
                        </div>
                        <div class="card-content">
                          <div class="media">
                            <div class="media-left">
                              <figure class="image is-48x48">
                                <img src="../assets/expo/HelladicLogo.jpg" alt="The Mycenaean Atlas Project">
                              </figure>
                            </div>
                            <div class="media-content">
                              <p class="title is-4">The Mycenaean Atlas Project</p>
                              <p class="title is-5">Archeology, Cultural Heritage</p>
                              <p class="subtitle is-6"><a href="https://twitter.com/Squinchpix" target="_blank" rel="noopener noreferrer">@Squinchpix</a></p>
                              <p class="subtitle is-6"><a href="http://www.helladic.info/" target="_blank" rel="noopener noreferrer">helladic.info</a></p>
                            </div>
                          </div>

                          <div class="content">
                            Very detailed Helladic (Mycenaean) sites information. All 3,800 sites elevations were updated using DEM Net Elevation API upon ASTER GDEM v3 dataset.
                            <br/>
                            <a href="http://mycenaeanatlasproject.blogspot.com/2019/11/a-convenient-api-for-retrieving.html" target="_blank" rel="noopener noreferrer">Blog post</a>
                            <br/>
                            <time datetime="2019-10-17">28 Nov 2019</time>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <!-- PATO -->
              <div class="tile is-4">
                  <div class="tile is-parent">
                      <div class="card">
                        <div class="card-image">
                          <figure class="image is-6by3">
                            <a href="https://www.nunatak.cl" target="_blank" rel="noopener noreferrer">
                            <img src="../assets/expo/Patopalpana.jpg" alt="Patricio Romero">
                            </a>
                          </figure>
                        </div>
                        <div class="card-content">
                          <div class="media">
                            <div class="media-left">
                            </div>
                            <div class="media-content">
                              <p class="title is-4">Patricio Romero</p>
                              <p class="title is-5">Archeology, Cultural Heritage</p>
                              <p class="subtitle is-6"><a href="https://twitter.com/pfromero" target="_blank" rel="noopener noreferrer">@pfromero</a></p>
                              <p class="subtitle is-6"><a href="https://www.nunatak.cl/" target="_blank" rel="noopener noreferrer">nunatak.cl</a></p>
                            </div>
                          </div>

                          <div class="content">
                            Patricio is a mapping a Chilean region, helping in climate analysis from fossil trees leaved by local indians on ceremonials. 
                            The API was used to map custom satellite imagery textures onto 3D models.
                            <br>
                            <time datetime="2019-10-17">17 Oct 2019</time>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <!-- RoyRodenhaeuser -->
              <div class="tile is-4">
                  <div class="tile is-parent">
                      <div class="card">
                        <div class="card-image">
                          <figure class="image is-6by3">
                            <a href="https://www.dymonlab.com?wix-vod-video-id=3cab6bd14de24f729c171d464eff07a2&wix-vod-comp-id=comp-jk5xciri" target="_blank" rel="noopener noreferrer">
                            <img src="https://i.vimeocdn.com/video/825336768_540x303.jpg" alt="Flood Tablet - Augmented UI">
                            </a>
                          </figure>
                        </div>
                        <div class="card-content">
                          <div class="media">
                            <div class="media-left">
                            </div>
                            <div class="media-content">
                              <p class="title is-4">Roy Rodenhaeuser @dymonlab.com</p>
                              <p class="title is-5">Augmented Research</p>
                              <p class="subtitle is-6"><a href="https://twitter.com/RoyRodenhaeuser" target="_blank" rel="noopener noreferrer">@RoyRodenhaeuser</a></p>
                              <p class="subtitle is-6"><a href="https://www.dymonlab.com" target="_blank" rel="noopener noreferrer">dymonlab.com</a></p>
                            </div>
                          </div>

                          <div class="content">
                            Roy Rodenhaeuser used the API to generate a 3D base map giving context in an AR setup for an archeology piece.
                            <br>
                            <time datetime="2019-10-17">Oct 2019</time>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="tile is-ancestor">
              <!-- http://javier.jimenezshaw.com/mapas/ -->
              <div class="tile">
                  <div class="tile is-parent is-4">
                      <div class="card">
                        <div class="card-image">
                          <figure class="image is-6by3">
                            <a href="http://javier.jimenezshaw.com/mapas" target="_blank" rel="noopener noreferrer">
                            <img src="../assets/expo/Multimapas.jpg" alt="Multimapas">
                            </a>
                          </figure>
                        </div>
                        <div class="card-content">
                          <div class="media">
                            <div class="media-left">
                            </div>
                            <div class="media-content">
                              <p class="title is-4">Multimaps</p>
                              <p class="subtitle is-6"><a href="http://javier.jimenezshaw.com/mapas" target="_blank" rel="noopener noreferrer">javier.jimenezshaw.com/mapas</a></p>
                            </div>
                          </div>

                          <div class="content">
                            Javier Jimenez Shaw as recently tested DEM Net Elevation API integration for elevation profiles requests. He has also helped to point out several bugs that were fixed. Check out his site, very helpful!
                            <br>
                            <time datetime="2019-10-17">Nov 2019</time>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <!-- -->
              <!-- -->
          </div>


        </div>

      

      </div>
  </div>
</template>

<script>
export default {
  name: 'Expo',
  components: {
    
  },
  created() {
  },
  methods: {
      
  },
  computed: {
   
  }, 
  data() {
        return {
            
      }
    }
}
</script>
